import clsx from 'clsx'
import Input from 'components/form/Input'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default function PayoutHistoryPage() {
  const [search, setSearch] = useState<string>('')

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    PayoutHistoryResponse,
    Error
  >(
    ['payout-history', search],
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `admin/investment/payout-history?page=${pageParam}&limit=20&query=${search}`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )
  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []

  return (
    <>
      <div className='flex justify-end items-end flex-wrap gap-3'>
        <Input
          className='!max-w-64 !w-auto'
          inputClassName='!h-12'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {/* <table className='mt-8'>
        <thead>
          <tr>
            <td>Timestamp</td>
            <td>Username</td>
            <td>Investment ID</td>
            <td>Payout Amount</td>
            <td>Yield</td>
            <td className='w-44'>Investment History</td>
            <td className='w-44'>Status</td>
          </tr>
        </thead>
        <tbody></tbody>
      </table> */}
      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx({
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Timestamp</td>
                <td>Username</td>
                <td>Amount</td>
                <td>Yield</td>
                <td>Investment ID</td>
                <td>Investment Amount</td>
                <td>Status</td>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.createdAt)}</td>
                  <td>{row.user?.username || 'N/A'}</td>
                  <td>{row.amount}</td>
                  <td>{row.investment?.package?.type.toUpperCase() || 'N/A'}</td>
                  <td>{row.investment?._id || 'N/A'}</td>
                  <td>{row.investment?.amount || 'N/A'}</td>
                  <td>{row.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  )
}
