import NiceModal, { useModal } from '@ebay/nice-modal-react'
import GradientText from 'components/GradientText'
import NoData from 'components/NoData'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { LoaderIcon } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

export default NiceModal.create(({ userId, username }: { userId: string; username: string }) => {
  const modal = useModal()

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<InvestsResponse, Error>(
    ['invests-by-user', userId],
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `admin/investment/list?page=${pageParam}&user=${userId}&status=active`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []

  return (
    <Modal
      title={
        <>
          <GradientText>“{username}”</GradientText> Investment History
        </>
      }
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-xl'
    >
      <div id='transaclistbyuser' className='h-[250px] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='transaclistbyuser'
        >
          <table>
            <thead>
              <tr>
                <td>Timestamp</td>
                <td>Amount</td>
              </tr>
            </thead>
            <tbody>
              {dataList?.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.startedAt)}</td>
                  <td>{row.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>

        {isLoading && (
          <div className='flex justify-center h-40 items-center'>
            <LoaderIcon />
          </div>
        )}

        {!isLoading && !dataList.length && <NoData />}
      </div>
    </Modal>
  )
})
