import RequireAuth from 'components/auth/RequireAuth'
import { AuthContext } from 'context/AuthContext'
import PageWrapper from 'layout/PageWrapper'
import { useContext, useEffect, useState } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import PageNotFound from './404'
import AddCryptoPage from './add-crypto'
import AddForexPage from './add-forex'
import AddHardAssetPage from './add-hard-asset'
import AddSubAdminPage from './add-sub-admin'
import AddTStocksPage from './add-t-stocks'
import AirdropPage from './airdrops'
import AllTransactionsPage from './all-transactions'
import CryptoPage from './crypto'
import Dashboard from './dashboard'
import EditSubAdminPage from './edit-sub-admin'
import ForexPage from './forex'
import HardAssetsPage from './hard-assets'
import InvestsPage from './invests'
import LoginPage from './login'
import PackagesPage from './packages'
import PayoutHistoryPage from './payout-history'
import SubAdminPage from './sub-admin'
import TStocksPage from './t-stocks'
import UsersPage from './users'
import WithdrawalsPage from './withdrawals'

const privateRoute: RouteObject[] = [
  {
    path: '/',
    element: <RequireAuth />,
    children: [
      { index: true, element: <Navigate to='dashboard' /> },
      {
        path: 'dashboard',
        element: (
          <PageWrapper title='Dashboard'>
            <Dashboard />
          </PageWrapper>
        ),
      },
      {
        path: 'users',
        element: (
          <PageWrapper title='All Users'>
            <UsersPage />
          </PageWrapper>
        ),
      },
      {
        path: 'pending-withdrawals',
        element: (
          <PageWrapper title='Withdrawals'>
            <WithdrawalsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'transactions',
        element: (
          <PageWrapper title='All Transactions'>
            <AllTransactionsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'invests',
        element: (
          <PageWrapper title='Invests'>
            <InvestsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'payout-history',
        element: (
          <PageWrapper title='Payout History'>
            <PayoutHistoryPage />
          </PageWrapper>
        ),
      },
      {
        path: 'crypto',
        element: (
          <PageWrapper title='Crypto'>
            <CryptoPage />
          </PageWrapper>
        ),
      },
      {
        path: 'crypto/add',
        element: (
          <PageWrapper title='Add Crypto'>
            <AddCryptoPage />
          </PageWrapper>
        ),
      },
      {
        path: 'forex',
        element: (
          <PageWrapper title='Forex'>
            <ForexPage />
          </PageWrapper>
        ),
      },
      {
        path: 'forex/add',
        element: (
          <PageWrapper title='Add Forex'>
            <AddForexPage />
          </PageWrapper>
        ),
      },
      {
        path: 't-stocks',
        element: (
          <PageWrapper title='T-Stocks'>
            <TStocksPage />
          </PageWrapper>
        ),
      },
      {
        path: 't-stocks/add',
        element: (
          <PageWrapper title='Add T-Stock'>
            <AddTStocksPage />
          </PageWrapper>
        ),
      },
      {
        path: 'hard-assets',
        element: (
          <PageWrapper title='Hard Assets'>
            <HardAssetsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'hard-assets/add',
        element: (
          <PageWrapper title='Add Hard Asset'>
            <AddHardAssetPage />
          </PageWrapper>
        ),
      },
      {
        path: 'sub-admins',
        element: (
          <PageWrapper title='Sub Admins'>
            <SubAdminPage />
          </PageWrapper>
        ),
      },
      {
        path: 'sub-admins/add',
        element: (
          <PageWrapper title='Add Sub Admin'>
            <AddSubAdminPage />
          </PageWrapper>
        ),
      },
      {
        path: 'sub-admins/edit',
        element: (
          <PageWrapper title='Edit Sub Admin'>
            <EditSubAdminPage />
          </PageWrapper>
        ),
      },
      {
        path: 'packages',
        element: (
          <PageWrapper title='Packages'>
            <PackagesPage />
          </PageWrapper>
        ),
      },
      {
        path: 'airdrop',
        element: (
          <PageWrapper title='Airdrop Requests'>
            <AirdropPage />
          </PageWrapper>
        ),
      },
      { path: 'login', element: <Navigate to='/' /> },
      { path: 'sign-up', element: <Navigate to='/' /> },
      { path: '*', element: <PageNotFound /> },
    ],
  },
]

const publicRoute: RouteObject[] = [
  { path: 'login', element: <LoginPage /> },
  { path: '*', element: <Navigate to='/login' /> },
]

const Routes = () => {
  const { token } = useContext(AuthContext)

  const [routes, setRoutes] = useState<RouteObject[]>(token ? privateRoute : publicRoute)

  // change routes on token state
  useEffect(() => {
    setRoutes(token ? privateRoute : publicRoute)
  }, [token])

  const appRoutes = useRoutes(routes)

  if (token) {
    return <div>{appRoutes}</div>
  }

  return <div>{appRoutes}</div>
}

export default Routes
