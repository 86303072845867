import clsx from 'clsx'
import { LoaderIcon, toast } from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'

import { useModal } from '@ebay/nice-modal-react'
import Tabs from 'components/Tabs'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'

const tabs: { label: string; value: AirdropStatus }[] = [
  {
    label: 'Claimed',
    value: 'claimed',
  },
  {
    label: 'Confirmed',
    value: 'confirmed',
  },
  {
    label: 'Declined',
    value: 'declined',
  },
]

export default function AirdropPage() {
  const queryClient = useQueryClient()
  const confirm = useModal(confirmationModal)

  const [activeTab, setActiveTab] = useState<TabOption>(tabs[0])
  const [search, setSearch] = useState<string>('')

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<AirdropsResponse, Error>(
    ['airdrops', search, activeTab.value],
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `admin/airdrop/list?page=${pageParam}&status=${activeTab.value}&limit=20&query=${search}`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const releaseAirdrop = useMutation<
    { message: string },
    Error,
    {
      airdropId: string
      hash: string
    }
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/airdrop/release', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('airdrops')
      },
    },
  )

  const declineAirdrop = useMutation<
    { message: string },
    Error,
    {
      airdropId: string
      reason: string
    }
  >(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/airdrop/decline', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('airdrops')
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []

  return (
    <>
      <div className='flex justify-between items-end flex-wrap gap-3'>
        <Tabs options={tabs} selectHandler={setActiveTab} selected={activeTab} />
        <Input
          className='!max-w-64 !w-auto'
          inputClassName='!h-12'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx('overflow-y-auto max-w-full', {
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Timestamp</td>
                <td>Username</td>
                <td>Email</td>
                <td>Coin</td>
                <td>Amount</td>
                <td>Wallet Address</td>
                <td>Status</td>
                {activeTab.value === 'claimed' && <td className='w-40'>Action</td>}
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.createdAt)}</td>
                  <td>{row.user.username}</td>
                  <td>{row.user.email}</td>
                  <td>{row.coin}</td>
                  <td>{row.amount && Number(row.amount?.toFixed(2))?.toLocaleString()} USDT</td>
                  <td>{row.address}</td>
                  <td className={`capitalize text-${StatusColorFinder(row.status)}`}>
                    {row.status}
                  </td>
                  {row.status === 'claimed' && (
                    <td className='flex gap-2'>
                      <Button
                        onClick={() =>
                          confirm
                            .show({
                              title: 'Confirm Airdrop',
                              description: 'Are you sure you want to confirm this airdrop?',
                              textRequired: true,
                              labelText: 'Transaction Hash',
                            })
                            .then((res) => {
                              if (!res) {
                                toast.error('Transaction hash is required')
                                return
                              }
                              toast.promise(
                                releaseAirdrop.mutateAsync({
                                  airdropId: row._id,
                                  hash: res as string,
                                }),
                                {
                                  loading: 'Confirming Airdrop...',
                                  success: (r) => r.message ?? 'Airdrop Confirmed',
                                  error: (r) => r.message ?? 'Failed to confirm airdrop',
                                },
                              )
                            })
                        }
                        size='md'
                      >
                        Confirm
                      </Button>
                      <Button
                        onClick={() =>
                          confirm
                            .show({
                              title: 'Decline Airdrop',
                              description: 'Are you sure you want to decline this airdrop?',
                              textRequired: true,
                              labelText: 'Reason',
                            })
                            .then((res) =>
                              toast.promise(
                                declineAirdrop.mutateAsync({
                                  airdropId: row._id,
                                  reason: res as string,
                                }),
                                {
                                  loading: 'Declining Airdrop...',
                                  success: (r) => r.message ?? 'Airdrop Declined',
                                  error: (r) => r.message ?? 'Failed to decline airdrop',
                                },
                              ),
                            )
                        }
                        size='md'
                        color='danger'
                      >
                        Decline
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  )
}
