import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

interface Form {
  id: string
  name?: string
  apy?: string
}

export default NiceModal.create(
  ({ userId, name, apy }: { userId: string; name: string; apy: string }) => {
    const modal = useModal()
    const queryClient = useQueryClient()

    const [form, setForm] = useState<Form>({
      id: userId,
      name: name,
      apy: apy,
    })

    const [errors, setErrors] = useState<Form>({
      id: '',
      name: '',
      apy: '',
    })

    const updateUser = useMutation<{ message: string }, Error, Form>(
      async (payload) => {
        try {
          const res = await privateRequest.patch('admin/user', payload)
          return res.data
        } catch (error) {
          errorHandler(error)
        }
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('users')
          modal.remove()
        },
      },
    )

    const changeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    }

    const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!form.id || !form.name) {
        setErrors((prev) => ({
          ...prev,
          id: !form.id ? 'ID is required' : '',
          name: !form.name ? 'Name is required' : '',
        }))
        return
      }
      // apy can be empty or greater than 0
      // if (form.apy && +form.apy <= 0) {
      //   setErrors((prev) => ({
      //     ...prev,
      //     apy:  'APY must be greater than 0',
      //   }))
      //   return
      // }
      toast.promise(updateUser.mutateAsync(form), {
        loading: 'Updating user...',
        success: (r) => r.message ?? 'User updated successfully',
        error: (err) => err.message ?? 'Failed to update user',
      })
    }

    return (
      <Modal
        title='Update User'
        visible={modal.visible}
        onCancel={() => modal.remove()}
        className='max-w-xl'
      >
        <form onSubmit={submitHandler} className='flex flex-col gap-6'>
          <Input
            label='Name'
            name='name'
            value={form.name}
            onChange={changeHandler}
            error={!!errors.name}
            helperText={errors.name}
          />
          <Input
            label='APY'
            name='apy'
            value={form.apy}
            onChange={changeHandler}
            error={!!errors.apy}
          />

          <Button disabled={updateUser.isLoading}>Submit</Button>
        </form>
      </Modal>
    )
  },
)
