type Props = React.SVGProps<SVGSVGElement>
export default function AirdropIcon({ width = 20, height = 20, ...rest }: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.99998 0C4.48593 0 0 4.48593 0 9.99998C0 13.7219 2.05168 17.1145 5.35429 18.854C5.64069 19.0048 5.99503 18.8949 6.14593 18.6087C6.29671 18.3223 6.18682 17.9679 5.90042 17.8172C2.9837 16.2809 1.17187 13.2856 1.17187 9.99998C1.17187 5.13214 5.13214 1.17187 9.99998 1.17187C14.8678 1.17187 18.8281 5.13214 18.8281 9.99998C18.8281 13.3392 16.9732 16.3568 13.9874 17.8752C13.699 18.0218 13.5841 18.3746 13.7308 18.663C13.8342 18.8664 14.04 18.9834 14.2535 18.9834C14.3429 18.9834 14.4336 18.963 14.5187 18.9196C16.1401 18.0952 17.5091 16.8434 18.4774 15.2997C19.4735 13.7115 20 11.8791 20 9.99998C20 4.48593 15.514 0 9.99998 0Z'
        fill='currentColor'
      />
      <path
        d='M15.8806 13.6977C16.579 12.5906 16.9481 11.312 16.9481 10C16.9481 6.16883 13.8312 3.05176 10 3.05176C6.16867 3.05176 3.05176 6.16883 3.05176 10C3.05176 11.312 3.4209 12.5905 4.11926 13.6977C4.79828 14.7737 5.75699 15.6438 6.89191 16.2137C7.18125 16.3588 7.5334 16.2421 7.67852 15.9529C7.82363 15.6636 7.70703 15.3114 7.41777 15.1663C5.44754 14.1771 4.22363 12.1976 4.22363 10C4.22363 6.81484 6.81488 4.22363 10 4.22363C13.185 4.22363 15.7762 6.81488 15.7762 10C15.7762 12.1976 14.5523 14.1772 12.5821 15.1663C12.2928 15.3116 12.1761 15.6636 12.3213 15.9529C12.4242 16.1577 12.6308 16.2761 12.8455 16.2761C12.9366 16.276 13.0265 16.2547 13.1079 16.2137C14.2439 15.6429 15.2025 14.773 15.8806 13.6977Z'
        fill='currentColor'
      />
      <path
        d='M13.8963 9.99984C13.8963 7.85141 12.1484 6.10352 9.99997 6.10352C7.85153 6.10352 6.10364 7.85141 6.10364 9.99984C6.10364 11.4824 6.92883 12.8177 8.25711 13.4843C8.33848 13.5253 8.42831 13.5467 8.51942 13.5468C8.73411 13.5468 8.94071 13.4284 9.04356 13.2236C9.18864 12.9343 9.07192 12.5822 8.78278 12.437C7.85309 11.9704 7.27551 11.0365 7.27551 9.99984C7.27551 8.49762 8.49774 7.27539 9.99997 7.27539C11.5022 7.27539 12.7244 8.49762 12.7244 9.99984C12.7244 11.0364 12.147 11.9702 11.2173 12.4368C10.9282 12.5821 10.8115 12.9341 10.9565 13.2234C11.1018 13.5126 11.454 13.6293 11.7431 13.4842C13.0713 12.8174 13.8963 11.4822 13.8963 9.99984Z'
        fill='currentColor'
      />
    </svg>
  )
}
