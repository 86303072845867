import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import Tabs from 'components/Tabs'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import copy from 'copy-to-clipboard'
import updateUserModal from 'features/users/update-user.modal'
import { useState } from 'react'
import { LoaderIcon, toast } from 'react-hot-toast'
import { BiCopy } from 'react-icons/bi'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

const tabs: TabOption[] = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Blocked',
    value: 'blocked',
  },
]

export default function UsersPage() {
  const confirmation = useModal(confirmationModal)
  const updateUserForm = useModal(updateUserModal)

  const queryClient = useQueryClient()

  const [activeTab, setActiveTab] = useState<TabOption>(tabs[0])
  const [search, setSearch] = useState<string>('')

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<UsersResponse, Error>(
    ['users', activeTab.value, search],
    async ({ pageParam = 1 }) => {
      try {
        const res = await privateRequest.get(
          `admin/user?page=${pageParam}&limit=20&status=${activeTab.value}&query=${search}`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage,
    },
  )

  const toggleBlock = useMutation<{ message: string }, Error, string>(
    async (id) => {
      try {
        const res = await privateRequest.patch(`admin/user/toggleStatus/${id}`)
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
      },
    },
  )

  const dataList = data?.pages?.flatMap((page) => page.docs) ?? []

  return (
    <>
      <div className='flex justify-between items-end flex-wrap gap-3'>
        <Tabs options={tabs} selectHandler={setActiveTab} selected={activeTab} />
        <Input
          className='!max-w-64 !w-auto'
          inputClassName='!h-12'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div id='scrollableDiv' className='h-[calc(100vh-230px)] mt-10 overflow-y-auto'>
        <InfiniteScroll
          dataLength={dataList.length}
          next={fetchNextPage}
          hasMore={!!hasNextPage}
          loader={
            <div className='flex gap-2 justify-center items-center'>
              <LoaderIcon />
              Loading...
            </div>
          }
          scrollableTarget='scrollableDiv'
        >
          <table
            className={clsx({
              'blur-sm animate-pulse': isLoading,
            })}
          >
            <thead>
              <tr>
                <td>Joined</td>
                <td>User ID</td>
                <td>Username</td>
                <td>Email</td>
                <td>Total Invest</td>
                <td>Current Invest</td>
                <td>Total Profit</td>
                <td className='w-64'>Action</td>
              </tr>
            </thead>

            <tbody>
              {isLoading && (
                <>
                  {Array(10)
                    .fill(0)
                    .map((_, i) => (
                      <tr key={i}>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                        <td>--</td>
                      </tr>
                    ))}
                </>
              )}
              {dataList.map((row) => (
                <tr key={row._id}>
                  <td>{dateFormatter(row.date)}</td>
                  <td>
                    {row._id && (
                      <Button
                        onClick={() => {
                          copy(row._id)
                          toast.success('Copied to clipboard')
                        }}
                        className='rounded-lg mr-2'
                        size='sm'
                      >
                        <BiCopy />
                      </Button>
                    )}{' '}
                    {row._id}
                  </td>
                  <td>{row.username}</td>
                  <td>{row.email}</td>
                  <td>{row.totalInvestment?.toFixed(2)} USDT</td>
                  <td>{row.currentInvestment?.toFixed(2)} USDT</td>
                  <td>{row.totalYield?.toFixed(2)} USDT</td>
                  <td className='flex gap-2'>
                    <Button
                      size='md'
                      onClick={() =>
                        updateUserForm.show({
                          userId: row._id,
                          name: row.name ?? '',
                          apy: row.apy ?? '',
                        })
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() =>
                        confirmation
                          .show({
                            description: `Are you sure you want to ${
                              row.status === 'active' ? 'Block' : 'Unblock'
                            } this Member?`,
                          })
                          .then(() =>
                            toast.promise(toggleBlock.mutateAsync(row._id), {
                              loading: 'Loading...',
                              success: `User ${row.status === 'active' ? 'blocked' : 'unblocked'}`,
                              error: 'Failed to update user status',
                            }),
                          )
                      }
                      variant='outlined'
                      size='md'
                    >
                      {row.status === 'active' ? 'Block' : 'Unblock'}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  )
}
