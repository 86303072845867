import NiceModal, { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/Button'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

interface Form {
  amount: string
  package?: Option
  userId: string
}

interface Payload {
  amount: string
  packageId: string
  userId: string
}

export default NiceModal.create(() => {
  const modal = useModal()
  const queryClient = useQueryClient()

  const [form, setForm] = useState<Form>({
    amount: '',
    userId: '',
  })

  const [errors, setErrors] = useState<{
    amount?: string
    package?: string
    userId?: string
  }>({})

  // const { data: allUsers, isLoading } = useQuery<UserData[], Error>(
  //   ['all-users-list', search],
  //   async () => {
  //     try {
  //       const res = await privateRequest.get(`admin/user?status=active&limit=-1&query=${search}`)
  //       return res.data.data.docs
  //     } catch (error) {
  //       errorHandler(error)
  //     }
  //   },
  // )

  const { data } = useQuery<Package[], Error>('packages', async () => {
    try {
      const res = await privateRequest.get('admin/packages')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const addInvestMutation = useMutation<{ message: string }, Error, Payload>(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/investment/subscribe', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('invests')
        modal.hide()
      },
    },
  )

  const changeHandler = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }))
  }

  const selectHandler = ({ target: { value, name } }: SelectElement) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }))
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!form.package || !form.amount || !form.userId) {
      setErrors((prev) => ({
        ...prev,
        package: !form.package ? 'Package is required' : '',
        amount: !form.amount ? 'Amount is required' : '',
        userId: !form.userId ? 'User ID is required' : '',
      }))
      return
    }

    const payload = {
      amount: form.amount,
      packageId: form.package.value,
      userId: form.userId,
    }

    toast.promise(addInvestMutation.mutateAsync(payload), {
      loading: 'Adding Invest...',
      success: (r) => r.message ?? 'Invest Added',
      error: (r) => r.message ?? 'Could not add invest',
    })
  }

  return (
    <Modal
      title='Add Invest'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-xl'
    >
      <form onSubmit={submitHandler} className='flex flex-col gap-6'>
        {/* <Select
          name='user'
          options={
            allUsers?.map((d) => ({
              label: `${d?.name ?? d.username} (${d.email})`,
              value: d._id,
            })) ?? []
          }
          onSearch={setSearch}
          search={search}
          value={form.user}
          label='Select User'
          placeholder='Select User'
          onChange={selectHandler}
          error={!!errors.user}
          helperText={errors.user}
          isLoading={isLoading}
          listClass='max-w-full'
        /> */}
        <Input
          label='User ID'
          name='userId'
          value={form.userId}
          onChange={changeHandler}
          error={!!errors.userId}
          helperText={errors.userId}
        />
        <Input
          label='Amount'
          name='amount'
          value={form.amount}
          onChange={changeHandler}
          error={!!errors.amount}
          helperText={errors.amount}
          prefix='$'
        />
        <Select
          name='package'
          options={
            data?.map((d) => ({
              label: d.type.toUpperCase(),
              value: d._id,
            })) ?? []
          }
          value={form.package}
          label='Investment Type'
          placeholder='Select Package'
          onChange={selectHandler}
          error={!!errors.package}
          helperText={errors.package}
        />
        <Button disabled={addInvestMutation.isLoading}>Submit</Button>
      </form>
    </Modal>
  )
})
